<template>
  <multiselect
    as="multiselect"
    class="multiselect-multiple"
    v-model="selected"
    :placeholder="placeholder"
    :show-labels="false"
    :options="options"
    :label="label"
    :track-by="trackBy"
    multiple
    :close-on-select="false"
    :class="[invalidClass]"
    :disabled="disabled"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>

</template>

<script>
import { get, call } from 'vuex-pathify'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: {
      type: Array
    },
    abbreviation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    professions: {
      type: Array,
      default: () => []
    },
  },

  data () {
    return {
      label: 'name',
      trackBy: 'id',
      placeholder: 'Add Qualifications',
    }
  },

  async created () {
    await this.getQualifications({ params: { professions: this.professions } })
  },

  watch: {
    professions: {
      immediate: true,
      handler: function () {
        this.getQualifications({ params: { professions: this.professions } })
      },
    }
  },

  computed: {
    ...get('lists/qualifications', ['qualifications']),

    options () {
      if (!this.abbreviation) {
        return this.qualifications
      }

      return this.qualifications.map(function (qualification) {
        return {
          id: qualification.id,
          name: `${qualification.abbreviation} - ${qualification.name}`,
          profession_id: qualification.profession_id,
        }
      })
    },

    selected: {
      get () {
        const ids = this.value.map(({ id }) => id)
        return this.options.filter(({ id }) => {
          return ids.includes(id)
        })
      },

      set (values) {
        this.$emit('input', values)
      }
    }
  },

  methods: {
    ...call('lists/qualifications', ['getQualifications']),
  },
}

</script>

<style>
  .list-data {
    margin-bottom: 0;
  }
</style>
