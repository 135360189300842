<template>
  <multiselect
    :options="options"
    :internal-search="internalSearch"
    placeholder="Select"
    :preselect-first="preselect"
    :show-labels="false"
    :label="label"
    :allow-empty="false"
    :disabled="disabled"
    :track-by="trackBy"
    v-model="selected"
    :class="[invalidClass]"
  />
</template>

<script>
import { useIsInvalid } from '@chameleon/chameleon-vue'
export default {
  mixins: [useIsInvalid],
  name: 'SingleSelect',

  props: {
    value: undefined,
    disabled: {
      type: Boolean,
      default: false
    },
    internalSearch: {
      type: Boolean,
      default: false
    },
    options: Array,
    preselect: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'label'
    },
    trackBy: {
      type: String,
      default: 'value'
    }
  },

  computed: {
    selected: {
      get () {
        if (this.value !== null) {
          return this.options.find(({ value }) => value === this.value) || this.preselectFirst
        }
        return this.preselectFirst
      },
      set (option) {
        this.$emit('input', option !== null ? option.value : this.preselectFirst)
      }
    },

    preselectFirst () {
      return this.preselect ? this.options[0] : null
    }
  }
}
</script>
