<template>
  <div class="authentication">
    <template v-if="verifying">
      <div class="card card-authentication">
        <div class="card-body">
          <img class="logo" src="@/assets/img/logo/primary-stacked.svg" alt="MedCPD" />

          <h1>Verifying Email</h1>

          <p class="text-muted text-large mb-0">
            Please wait while we verify your email.
          </p>
        </div>
      </div>
    </template>
    <verification-failed
      v-else-if="verificationFailed"
      :error="verificationFailedError"
    />
    <div class="card card-authentication" v-else-if="verificationSucceeded">
      <div class="card-body">
        <router-link :to="{ path: '/' }">
          <img
            class="logo"
            src="@/assets/img/logo/primary-stacked.svg"
            alt="MedCPD"
          />
        </router-link>

        <h1>Set Password</h1>

        <validation-observer ref="validation">
          <validation-provider
            tag="div"
            class="mb-3"
            name="password"
            rules="required|min:8|contains_special_character|contains_number|contains_uc_character|contains_lc_character"
          >
            <label>New Password</label>
            <c-input
              v-model="payload.password"
              id="password"
              type="password"
              class="form-control"
            />
            <c-help-block />
          </validation-provider>

          <validation-provider
            tag="div"
            class="mb-3"
            name="password confirmation"
            :rules="{ required: true, is: payload.password }"
          >
            <label>Repeat New Password</label>
            <c-input
              type="password"
              class="form-control"
              v-model="payload.password_confirmation"
            />
            <c-help-block />
          </validation-provider>

          <p class="label">Password Strength</p>
          <password-strength-bar class="mb-3" :password="payload.password" />

          <div class="mt-4">
            <validation-provider
              tag="div"
              name="terms and conditions"
              vid="agree_terms"
              :rules="{ required: { allowFalse: false } }"
            >
              <c-checkbox v-model="payload.terms_accepted">
                I agree to the
                <a
                  class="text-decoration-underline"
                  href="https://medcpd.com/privacy-policy/"
                  target="_blank"
                >
                  privacy policy
                </a>
                and
                <router-link
                  class="text-decoration-underline"
                  :to="{ name: 'terms' }"
                  target="_blank"
                >
                  terms and conditions
                </router-link>
                of use.
              </c-checkbox>
            </validation-provider>

            <validation-provider
              v-if="$isA('provider') || $isA('user')"
              tag="div"
              name="marketing"
              vid="agree_marketing"
              :rules="{ required: { allowFalse: true } }"
            >
              <c-checkbox v-model="payload.receive_marketing">
                I would like to receive occasional marketing communication from
                Med CPD.
              </c-checkbox>
            </validation-provider>

            <div class="row" v-if="$isA('provider')">
              <div class="col">
                <validation-provider
                  tag="div"
                  name="feedback participation"
                  vid="agree_feedback"
                  :rules="{ required: { allowFalse: true } }"
                >
                  <c-checkbox v-model="payload.participate_feedback">
                    I would like to participate in the community feedback
                    program.
                  </c-checkbox>
                </validation-provider>
              </div>
              <div class="col-auto d-flex align-items-center">
                <button
                  type="button"
                  data-bs-toggle="popover"
                  data-bs-content="By participating in the community feedback program we will occasionally ask you to provide feedback on what training courses you are looking for and how providers could improve them."
                  class="btn btn-question-popover"
                  data-bs-original-title=""
                  title=""
                  aria-describedby="popover69234"
                >
                  <i class="far fa-question-circle"></i>
                </button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-auto text-end d-flex align-items-center">
                <router-link class="small text-muted" :to="{ name: 'logout' }">
                  <i class="far fa-arrow-left"></i>
                  Back to log in.
                </router-link>
              </div>

              <div class="col text-end">
                <c-button @click="submit" validate variant="primary">
                  Save
                </c-button>
              </div>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { routeNavigationFailure } from '@codium/codium-vue'
import { get, call } from 'vuex-pathify'
import { http } from '@/utils'
import PasswordStrengthBar from '@/components/PasswordStrengthBar'
import VerificationFailed from '../auth/AccountVerificationFailed'

export default {
  props: {
    token: String
  },

  components: {
    VerificationFailed,
    PasswordStrengthBar
  },

  computed: {
    user: get('auth/user')
  },

  data () {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        terms_accepted: false,
        receive_marketing: false,
        participate_feedback: false,
      },
      verifying: true,
      verificationFailed: false,
      verificationSucceeded: false,
      verificationFailedError: null
    }
  },

  async created () {
    if (!this.user.id) {
      await this.verify()
    } else {
      this.verifying = false
      this.verificationSucceeded = true

      if (this.user.terms_accepted) {
        this.$router.replace({ name: 'dashboard' }).catch(routeNavigationFailure)
      }
    }
  },

  methods: {
    logout: call('auth/logout'),
    authorize: call('auth/authorize'),
    onboardUser: call('users/onboardUser'),

    async verify () {
      try {
        await http.post('/auth/verification', {
          signature: this.token,
          identifier: this.$route.query.identifier,
          expires: this.$route.query.expires
        }, {
          throwForbiddens: true
        })

        // After verification we need to re-authorize to fetch the fresh user instance.
        await this.authorize()

        await new Promise((resolve) => {
          setTimeout(resolve, 1500)
        })

        this.verificationSucceeded = true
      } catch (error) {
        this.verificationFailed = true

        if (error.response) {
          this.verificationFailedError = error.response.data.message
        }
      } finally {
        this.verifying = false
      }
    },

    async submit () {
      await this.onboardUser({ id: this.$user.id, payload: this.payload })

      toast('Your account has completed onboarding.', 'success')

      await this.authorize()

      this.$router.replace({ name: 'dashboard' }).catch(routeNavigationFailure)
    }
  }
}
</script>
