<template>
  <div class="card card-authentication card-authentication-lg">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>

      <p class="text-success strong text-center">
        <i class="far fa-shield-check"></i> Thank you for verifying your account.
      </p>

      <div class="decorative-divider"></div>

      <h2>Select a subscription tier</h2>

      <p class="text-muted">
        Unlock the power of Med CPD's tailored subscription for Professional
        Development Providers, amplifying your visibility and success in the
        world of medical professional development. Choose the plan that suits
        your needs and watch your content thrive.
      </p>

      <ul class="list-data list-data-plan-select no-hover no-shadow">
        <subscription-provider-tier-item
          v-for="pkg in subscriptionPlansOrdered"
          :key="pkg.id"
          :pricing="pkg"
          v-model="selected"
        />
      </ul>

      <p class="text-muted" style="white-space: pre-line;">
        Experience the Med CPD advantage today. Elevate your offerings, enjoy
        high quality engagement with interested learners and stand out from the
        competition. Choose your subscription level and join the Med CPD community now.
      </p>

      <p>
        <small class="text-muted font-italic">
          Prices shown are in Australian Dollars (AUD) and inclusive of GST.
        </small>
      </p>

      <div class="text-end">
        <router-link class="btn btn-primary" tag="button" :disabled="noPlansSelected" :to="{ name: 'register.subscribe', query: { plan: selected } }">
          Subscribe
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import SubscriptionProviderTierItem from '@/components/subscriptions/SubscriptionProviderTierItem.vue'
import { authMethods } from '@/vuex/helpers'

export default {
  components: {
    SubscriptionProviderTierItem,
  },

  data () {
    return {
      selected: null,
    }
  },

  async created () {
    await this.authorize()
    if (this.$user.has_subscription) {
      this.$router.replace({ name: 'dashoard' })
    }

    await this.getPackages()
  },

  computed: {
    subscriptionPlan: get('packages/packages'),

    subscriptionPlansOrdered () {
      if (!this.subscriptionPlan?.pricings) {
        return []
      }

      // Sort packages by price, with enterprise package at the end
      return [...this.subscriptionPlan.pricings].sort((a, b) => {
        if (a.price === 0) return 1
        if (b.price === 0) return -1

        return a.price - b.price
      })
    },

    noPlansSelected () {
      return this.selected === null
    }
  },

  methods: {
    ...authMethods,
    getPackages: call('packages/getPackages'),
  },
}
</script>
