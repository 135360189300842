<template>
  <fragment>
    <h1>Create Medical Professional Account</h1>
    <div class="card card-register-notice mb-4">
      <div class="card-body">
        <p class="text-muted pb-0">Are you a professional development provider wanting to list CPD courses?
          <router-link :to="{ name: 'register-provider' }">Register here</router-link>.
        </p>
      </div>
    </div>

    <div class="mb-3">
      <p class="form-label">First Name</p>
      <div class="row">
        <!-- title -->
        <validation-provider tag="div" class="col-4 col-sm-3" name="title" rules="required">
          <label class="visually-hidden">Title</label>
          <single-select v-model="title" :options="optionsTitle" :preselect="true"/>
          <c-help-block />
        </validation-provider>
        <!-- first name -->
        <validation-provider tag="div" class="col" name="first name" rules="required">
          <label class="visually-hidden">First Name</label>
          <c-input type="text" v-model="firstName" />
          <c-help-block />
        </validation-provider>
      </div>
    </div>

    <!-- last name -->
    <validation-provider tag="div" class="mb-3" name="last name" rules="required">
      <label class="form-label">Last Name</label>
      <c-input type="text" v-model="lastName" />
      <c-help-block/>
    </validation-provider>

    <!-- email -->
    <validation-provider tag="div" class="mb-4" name="email" rules="required|email">
      <label class="form-label">Email</label>
      <c-input type="email" v-model="email"/>
      <c-help-block/>
    </validation-provider>

    <!-- password -->
    <validation-provider
      tag="div"
      name="password"
      rules="required|min:8|contains_special_character|contains_number|contains_uc_character|contains_lc_character"
      class="mb-3"
    >
      <label class="form-label">Password</label>
      <c-input type="password" v-model="password"/>
      <c-help-block/>
    </validation-provider>
    <password-strength-bar v-bind="{ password, label: 'Password Strength' }" class="mb-3" />

    <div class="mb-4 mt-4">
      <!-- terms -->
      <validation-provider tag="div" name="terms and conditions" vid="agree_terms" :rules="{ required: { allowFalse: false } }">
        <c-checkbox v-model="termsAccepted">
          I agree to the
          <a href="https://medcpd.com/privacy-policy/" class="text-decoration-underline" target="_blank">privacy policy</a>
          and
          <router-link class="text-decoration-underline" :to="{ name: 'terms' }" target="_blank">terms and conditions</router-link>
            of use.
        </c-checkbox>
      </validation-provider>

      <!-- marketing -->
      <validation-provider tag="div" name="marketing" vid="agree_marketing" :rules="{ required: { allowFalse: true } }">
        <c-checkbox v-model="receiveMarketing">
          I would like to receive occasional marketing communication from Med CPD.
        </c-checkbox>
      </validation-provider>

      <!-- feedback participation -->
      <div class="row">
        <div class="col">
          <validation-provider tag="div" name="feedback participation" vid="agree_feedback" :rules="{ required: { allowFalse: true } }">
            <c-checkbox v-model="participateFeedback">
              I would like to participate in the community feedback program.
            </c-checkbox>
          </validation-provider>
        </div>
        <div class="col-auto d-flex align-items-center">
          <button type="button" data-bs-toggle="popover" data-bs-content="By participating in the community feedback program we will occasionally ask you to provide feedback on what training courses you are looking for and how providers could improve them." class="btn btn-question-popover" data-bs-original-title="" title="" aria-describedby="popover69234"><i class="far fa-question-circle"></i>
          </button>
        </div>
      </div>

    </div>

    <slot name="button" v-bind="{ payload }"/>
  </fragment>
</template>

<script>
import PasswordStrengthBar from '@/components/forms/PasswordStrengthBar'
import SingleSelect from '../../components/forms/SingleSelect.vue'
import { Popover } from 'bootstrap'

export default {
  props: {
    courseId: {
      type: String || null,
      required: false,
    }
  },

  components: {
    PasswordStrengthBar,
    SingleSelect,
  },

  data () {
    return {
      title: 'Mr',
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      termsAccepted: false,
      popover: null,
      receiveMarketing: false,
      participateFeedback: false,
      optionsTitle: [
        { label: 'Dr', value: 'Dr' },
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Prof', value: 'Prof' },
        { label: 'A/Prof', value: 'A/Prof' },
      ]
    }
  },
  mounted () {
    this.popover = new Popover(document.querySelector('[data-bs-toggle="popover"]'), { container: 'body' })
  },

  computed: {
    payload () {
      return {
        title: this.title,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        terms_accepted: this.termsAccepted,
        receive_marketing: this.receiveMarketing,
        participate_feedback: this.participateFeedback,
        course_redirect_hash: this.courseId
      }
    }
  }
}
</script>
