<template>
  <div class="card card-authentication card-authentication-lg">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>

      <p class="text-success strong text-center">
        <i class="far fa-shield-check"></i> Thank you for verifying your account.
      </p>

      <div class="decorative-divider"></div>

      <h2>Join Med CPD Premium</h2>

      <div class="row mt-4 mb-3">
        <div class="col-auto">
          <p class="h4 text-primary">$15 /month</p>
        </div>
        <div class="col ps-4">
          <p class="text-muted small">or $150 per year (Save $30)</p>
        </div>
      </div>

      <p class="strong">Free 30 day trial, cancel at any time.</p>

      <p class="text-muted">
        Elevate your career with Med CPD Premium, your gateway to superior
        professional development. For just $15 a month, access a world of
        exclusive features: <br/><br />

        <b>Unlimited Course Access:</b><br />
        Explore a diverse library of courses and content that meets all of your needs.<br /><br />

        <b>Personalised Learning Plans:</b><br />
        Organise and plan your CPD journey with your personalised tailored learning plans.<br /><br />

        <b>Professional Development Plan Management:</b><br />
        Grow your career effortlessly with guided plans that track your activities to maximise your investment.<br /><br />

        <b>Support the Med CPD community:</b><br />
        Invest in a community that is invested in you, be part of a community that exists entirely to support your career in health.<br />
        Invest in Med CPD Premium today and unlock the full potential of your career in health.
      </p>

      <p>
        <small class="text-muted font-italic">
          Prices shown are in Australian Dollars (AUD) and inclusive of GST.
        </small>
      </p>

      <div class="text-end">
        <router-link class="btn btn-light text-primary" :to="{ name: 'dashboard' }">
          Skip For Now
        </router-link>

        <router-link class="btn btn-primary" :to="{ name: 'register.subscribe' }">
          Subscribe
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  created () {
    this.getPackages()
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
  },

  methods: {
    getPackages: call('packages/getPackages'),
  },
}
</script>
