<template>
  <validation-observer tag="div" class="authentication">
    <div class="card card-authentication">
      <div class="card-body">
        <router-link :to="{ name: 'login' }">
          <img class="logo" src="@/assets/img/logo/primary-stacked.svg" alt="MedCPD" />
        </router-link>
        <register-form :courseId="courseId">
          <template #button="{ payload }">
            <div class="mx-4">
              <c-button class="btn-primary btn-lg w-100" @click="register(payload)" validate>
                Create Account
              </c-button>
            </div>
          </template>
        </register-form>
      </div>

      <!-- login -->
      <div class="card-footer border-top">
        <p class="text-muted">Already have an account? <router-link :to="{ name: 'login' }">Login</router-link></p>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { http } from '@/utils'
import { call } from 'vuex-pathify'
import RegisterForm from '@/views/register/RegisterForm'
import { toast } from '@chameleon/chameleon-vue'
import Cookies from 'js-cookie'

export default {
  data () {
    return {
      courseId: null,
    }
  },

  components: {
    RegisterForm
  },

  created () {
    if (this.$route.query.invite) {
      Cookies.set('invite', this.$route.query.invite, { expires: 7 })

      this.$router.replace({ name: 'register-user' })
    }

    if (this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId
    }
  },

  methods: {
    authorize: call('auth/authorize'),

    async register (payload) {
      try {
        const invite = Cookies.get('invite')

        await http.post('register', { ...payload, invite })

        Cookies.remove('invite')

        await this.authorize()

        this.$router.push({ name: 'register-user-onboard' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
