<template>
  <div class="card card-authentication">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>

      <div class="row">
        <div class="col-auto">
          <div class="pb-2">
            <div class="swal-icon swal-icon--success mx-2">
              <div
                class="swal-icon--success__line swal-icon--success__line--long"
              ></div>
              <div
                class="swal-icon--success__line swal-icon--success__line--tip"
              ></div>
              <div class="swal-icon--success__ring"></div>
              <div class="swal-icon--success__hide-corners"></div>
            </div>
          </div>
        </div>
        <div class="col">
          <h1 class="mb-4">Account Verified</h1>
          <p class="text-muted">
            Thank you for verifying your email address, this helps us keep your
            account secure.
          </p>
          <router-link :to="{ name: 'dashboard' }" class="btn btn-primary">Continue</router-link>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
