<template>
  <validation-observer tag="div" class="authentication">
    <div class="card card-authentication">
      <div class="card-body">
        <router-link :to="{ name: 'login' }">
          <img class="logo" src="@/assets/img/logo/primary-stacked.svg" alt="MedCPD" />
        </router-link>
        <input-form>
          <template #button="{ payload }">
            <div class="row mt-4">
              <div class="col-auto text-end d-flex align-items-center">
                <router-link class="small text-muted" :to="{ name: 'logout' }">Sign Out</router-link>
              </div>
              <div class="col text-end">
                <c-button class="btn-primary" @click="submit(payload)" validate>
                  Next
                </c-button>
              </div>
            </div>
            <div class="mx-4">

            </div>
          </template>
        </input-form>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { http } from '@/utils'
import { call } from 'vuex-pathify'
import InputForm from '@/views/register/OnboardUserForm'
import { toast } from '@chameleon/chameleon-vue'

export default {
  components: {
    InputForm
  },

  methods: {
    authorize: call('auth/authorize'),

    async submit (payload) {
      try {
        const ids = payload.qualifications.map(qualification => qualification.id)
        payload.qualifications = ids
        await http.put(`register/${this.$user.id}`, payload)
        await this.authorize()
        this.$router.push({ name: 'verification' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
