<template>
  <multiselect
    as="multiselect"
    class="multiselect-multiple"
    v-model="selected"
    :placeholder="placeholder"
    :show-labels="false"
    :options="options"
    :label="label"
    :track-by="trackBy"
    multiple
    :close-on-select="false"
    :class="[invalidClass]"
    :disabled="disabled"
  >
    <template #tag>
      <span></span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title" :class="(props.option.parent_id) ? 'is-child' : ''">{{ props.option.name }}</span>
      </div>
    </template>
  </multiselect>

</template>

<script>
import { http } from '@/utils'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: {
      type: Array
    },
    abbreviation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    professions: {
      type: Array,
      default: () => []
    },
  },

  data () {
    return {
      options: [],
      label: 'name',
      trackBy: 'id',
      placeholder: 'Add Topics',
    }
  },

  watch: {
    professions () {
      this.getTopics()
    },
  },

  computed: {
    selected: {
      get () {
        const ids = this.value.map(({ id }) => id)
        return this.options.filter(({ id }) => {
          return ids.includes(id)
        })
      },

      set (values) {
        this.$emit('input', values)
      }
    }
  },

  methods: {
    async getTopics () {
      const topicsResponse = await http.get('/lists/topics', {
        params: {
          professions: this.professions,
        }
      })

      this.options = topicsResponse.data.data.flatMap((topic) => {
        return [topic, ...topic.children]
      })

      if (this.abbreviation) {
        const abbreviated = this.options.map(function (option) {
          return { id: option.id, name: `${option.abbreviation} - ${option.name}` }
        })
        this.options = abbreviated
      }
    },
  },
}

</script>

<style>
.is-child {
  padding-left: 10px;
  font-size: 13px;
}
</style>
