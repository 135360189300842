<template>
  <c-button type="button" class="btn btn-primary" @click="click">
    Resend Email
  </c-button>
</template>

<script>
import { http } from '@/utils'
import swal from 'sweetalert'

export default {
  methods: {
    async click () {
      try {
        await http.post('auth/verification/resend')

        swal({
          icon: 'success',
          title: 'Verification Email Sent',
          text: 'A verification email has been sent. The link in this email is valid for 24 hours.',
          buttons: {
            cancel: {
              text: 'Close',
              value: null,
              visible: true,
              className: 'btn btn-primary',
              closeModal: true
            }
          }
        })
      } catch (error) {
        //
      }
    }
  }
}
</script>
