<template>
  <c-input
    as="multiselect"
    :class="invalidClass"
    v-model="selected"
    :placeholder="placeholder"
    :preselect-first="prependAll"
    :show-labels="false"
    :options="options"
    label="name"
    track-by="id"
    :disabled="disabled"
  >
  <template slot="prepend"><slot name="prepend" /></template>
  </c-input>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: Number,
    prependAll: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    professionId: {
      type: Number,
      default: null
    },
    activeCountries: Boolean
  },

  watch: {
    professionId: {
      immediate: false,
      handler (professionId) {
        this.getCpdBodies({ params: { profession_id: professionId } })
      }
    }
  },

  computed: {
    cpdBodies: get('lists/cpdBodies/cpdBodies'),

    options () {
      const options = [...this.cpdBodies]

      if (this.prependAll) {
        options.unshift({ name: 'All', id: null })
      }

      return options
    },

    selected: {
      get () {
        return this.options.find(({ id }) => id === this.value)
      },

      set (option) {
        this.$emit('input', option ? option.id : null)
      }
    }
  },

  async created () {
    await this.getCpdBodies({ params: { profession_id: this.professionId } })
  },

  methods: {
    getCpdBodies: call('lists/cpdBodies/getCpdBodies')
  }
}
</script>
