<template>
  <validation-observer tag="div" class="authentication">
    <div class="card card-authentication">
      <div class="card-body">
        <router-link :to="{ name: 'login' }">
          <img class="logo" src="@/assets/img/logo/primary-stacked.svg" alt="MedCPD" />
        </router-link>
        <register-form>
          <template #button="{ payload }">
            <div class="mx-4">
              <c-button class="btn-primary btn-lg w-100" @click="submit(payload)" validate>
                Create Account
              </c-button>
            </div>
          </template>
        </register-form>
      </div>

      <!-- login -->
      <div class="card-footer border-top">
        <p class="text-muted">Already have an account? <router-link :to="{ name: 'login' }">Login</router-link></p>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { http } from '@/utils'
import { call } from 'vuex-pathify'
import RegisterForm from '@/views/register/RegisterProviderForm'
import swal from 'sweetalert'
import { toast } from '@chameleon/chameleon-vue'
import Cookies from 'js-cookie'

export default {
  components: {
    RegisterForm
  },

  methods: {
    authorize: call('auth/authorize'),

    async submit (payload) {
      try {
        const invite = Cookies.get('invite')

        await http.post('register-provider', { ...payload, invite })

        Cookies.remove('invite')

        await this.authorize()
        this.$router.push({ name: 'register-provider-onboard' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.message === 'provider_exists') {
            const result = await swal({
              icon: 'warning',
              title: 'A professional development provider with this name already exists.',
              text: 'If you believe this is a mistake or you are unable to get access to your existing account please contact Med CPD for assistance.',
              buttons: {
                cancel: { text: 'Close', value: false, visible: true, className: 'swal-button swal-button--cancel btn btn-outline-dark' },
                ok: { text: 'Contact', value: true, visible: true, className: 'swal-button swal-button--contact btn btn-primary' },
              },
              dangerMode: true
            })
            if (result) {
              this.$router.push({ name: 'contact' })
            }
          } else {
            toast(error.response.data.message, 'danger')
          }
        } else {
          throw error
        }
      }
    }
  }
}
</script>
