<template>
  <multiselect
    as="multiselect"
    :options="professions"
    :class="invalidClass"
    label="name"
    track-by="id"
    placeholder="Select"
    v-model="professionSelected"
    :preselect-first="false"
    :show-labels="false"
    :allow-empty="true"
    :multiple="multiple"
  >
    <template #tag v-if="hideTag">
      <span></span>
    </template>
  </multiselect>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: {
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideTag: {
      type: Boolean,
      default: false,
    },
  },

  async created () {
    await this.getProfessions().catch(() => {})
  },

  computed: {
    ...get('lists/professions', ['professions']),

    professionSelected: {
      get () {
        if (this.multiple) {
          return this.professions
            .filter(({ id }) => {
              return (this.value ?? []).includes(id)
            })
        }

        return this.professions.find(({ id }) => id === this.value)
      },

      set (values) {
        if (this.multiple) {
          this.$emit('input', values.map(({ id }) => id))
          return
        }

        this.$emit('input', values?.id)
      }
    },
  },

  methods: {
    ...call('lists/professions', ['getProfessions']),
  },
}
</script>
