<template>
  <fragment>
    <h1>Setup Account</h1>
    <p class="mb-4">Setting up an account helps us tailor your experience and professional development courses.</p>

    <!-- dob -->
    <label class="form-label">Date of Birth</label>
    <date-of-birth-select v-model="dob" key="id"/>

    <!-- ahpra number -->
    <div class="mb-3">
      <label for="" class="form-label">AHPRA number</label>
      <c-input type="text" v-model="ahpra_number" />
    </div>

    <!-- location -->
    <validation-provider tag="div" class="mb-3" name="location" rules="required">
      <label class="form-label">Location</label>
      <single-select v-model="location" :options="optionsLocation"/>
      <c-help-block />
    </validation-provider>

    <!-- profession -->
    <validation-provider tag="div" class="mb-3" name="profession" rules="required">
      <label class="form-label">Profession</label>
      <profession-select v-model="profession_id" />
      <c-help-block />
    </validation-provider>

    <!-- primary field -->
    <validation-provider tag="div" class="mb-3" name="primary field" rules="required">
      <label class="form-label">Primary Field</label>
      <primary-field-select
        v-model="primary_field_id"
        label="name"
        key="id"
        :profession-id="profession_id"
        :disabled="!profession_id"
      />
      <c-help-block />
    </validation-provider>

    <!-- cpd home -->
    <validation-provider tag="div" class="mb-3" name="cpd home" vid="cpd_body_id" rules="required">
      <label class="form-label">CPD Body</label>
      <cpd-body-select
        v-model="cpd_body_id"
        label="name"
        key="id"
        :profession-id="profession_id"
        :disabled="!profession_id"
      />
      <c-help-block />
    </validation-provider>

    <!-- qualifications -->
    <validation-provider tag="div" class="mb-3" name="qualifications" rules="required">
      <label class="form-label">Qualifications</label>
      <qualifications-select
        v-model="qualifications"
        placeholder="Add Qualifications"
        :professions="[profession_id]"
        :disabled="!profession_id"
      />
      <c-help-block />
      <ul class="list-data list-data-qualifications no-links no-hover has-button">
        <li v-for="item in qualifications" :key="item.id" v-bind="{ item }">
          {{ item.name }}
          <button type="button" @click="removeQualification(item)" class="btn btn-sm text-danger">
            <i class="far fa-times"></i>
          </button>
        </li>
      </ul>
    </validation-provider>

    <!-- country of study -->
    <validation-provider tag="div" class="mb-3" name="country of study" vif="country_of_study" rules="required">
      <label class="form-label">Country of Study</label>
      <country-select v-model="country_of_study_id" label="name" key="id" activeCountries @input="updateUniversities"/>
      <c-help-block />
    </validation-provider>

    <!-- university -->
    <validation-provider tag="div" class="mb-3" name="university" rules="required" v-if="country_of_study_id">
      <label class="form-label">University</label>
      <university-select v-model="university_id" label="name" key="id" :countryId="country_of_study_id"/>
      <c-help-block />
    </validation-provider>

    <div class="mb-3">
      <p class="form-label">Do you work in the public or private sector?</p>
      <!-- public sector -->
      <c-checkbox v-model="public_sector" class="form-check-inline">
        Public
      </c-checkbox>
      <!-- private sector -->
      <c-checkbox v-model="private_sector" class="form-check-inline">
        Private
      </c-checkbox>
    </div>

     <!-- interests -->
    <validation-provider tag="div" class="mb-3" name="topics" rules="required">
      <label class="form-label">Interests</label>
      <topics-select
        v-model="topics"
        placeholder="Add Topics"
        :professions="[profession_id]"
        :disabled="!profession_id"
      />
      <c-help-block />
      <ul class="list-data list-data-topics no-links no-hover has-button">
        <li v-for="item in topics" :key="item.id" v-bind="{ item }">
          {{ item.name }}
          <button type="button" @click="removeTopic(item)" class="btn btn-sm text-danger">
            <i class="far fa-times"></i>
          </button>
        </li>
      </ul>
    </validation-provider>

    <slot name="button" v-bind="{ payload }"/>
  </fragment>
</template>

<script>
import SingleSelect from '@/components/forms/SingleSelect.vue'
import QualificationsSelect from '@/components/qualifications/QualificationsSelect.vue'
import UniversitySelect from '@/components/universities/UniversitySelect.vue'
import CountrySelect from '@/components/forms/CountrySelect.vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'
import PrimaryFieldSelect from '@/components/forms/PrimaryFieldSelect.vue'
import CpdBodySelect from '@/components/forms/CpdBodySelect.vue'
import DateOfBirthSelect from '@/components/dob/DateOfBirthSelect.vue'
import TopicsSelect from '@/components/topics/TopicsSelect.vue'
import { bus } from '@/main'

export default {
  components: {
    SingleSelect,
    QualificationsSelect,
    UniversitySelect,
    TopicsSelect,
    DateOfBirthSelect,
    CountrySelect,
    ProfessionSelect,
    PrimaryFieldSelect,
    CpdBodySelect,
  },

  data () {
    return {
      dob: null,
      location: null,
      ahpra_number: null,
      profession_id: null,
      primary_field_id: null,
      university_id: null,
      country_of_study_id: null,
      cpd_body_id: null,
      qualifications: [],
      public_sector: false,
      private_sector: false,
      topics: [],
      optionsLocation: [
        { label: 'Australian Capital Territory', value: 'act' },
        { label: 'New South Wales', value: 'nsw' },
        { label: 'Northern Territory', value: 'nt' },
        { label: 'Queensland', value: 'qld' },
        { label: 'Tasmania', value: 'tas' },
        { label: 'South Australia', value: 'sa' },
        { label: 'Victoria', value: 'vic' },
        { label: 'Western Australia', value: 'wa' },
      ],
    }
  },

  watch: {
    profession_id () {
      this.primary_field_id = null
      this.cpd_body_id = null
      this.qualifications = []
      this.topics = []
    },
  },

  computed: {
    payload () {
      return {
        dob: this.dob,
        location: this.location,
        ahpra_number: this.ahpra_number,
        profession_id: this.profession_id,
        primary_field_id: this.primary_field_id,
        university_id: this.university_id,
        country_of_study_id: this.country_of_study_id,
        cpd_body_id: this.cpd_body_id,
        qualifications: this.qualifications,
        public_sector: this.public_sector,
        private_sector: this.private_sector,
        topics: this.topics.map(({ id }) => id),
      }
    },
  },

  methods: {
    removeQualification (item) {
      const index = this.qualifications.findIndex((qualification) => qualification.id === item.id)
      this.qualifications.splice(index, 1)
    },

    removeTopic (item) {
      const index = this.topics.findIndex((topic) => topic.id === item.id)
      this.topics.splice(index, 1)
    },

    updateUniversities () {
      bus.$emit('updateUniversities', this.country_of_study_id)
    }
  },
}
</script>
