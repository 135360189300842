<template>
  <fragment>
    <h1>Create Professional Development Provider Account</h1>
    <div class="card card-register-notice mb-4">
      <div class="card-body">
        <p class="text-muted pb-0">Are you a medical professional looking for CPD courses and training?
          <router-link :to="{ name: 'register-user' }">Register here</router-link>.
        </p>
      </div>
    </div>

    <div class="mb-3">
      <!-- company -->
      <validation-provider tag="div" class="mb-3" name="company" rules="required">
        <label class="form-label">Company / Organisation Name</label>
        <c-input type="text" v-model="company" />
        <c-help-block />
      </validation-provider>
      <!-- acronym -->
      <validation-provider tag="div" class="mb-3" name="provider acronym" rules="required">
        <label class="form-label">Company / Organisation Acronym</label>
        <c-input type="text" v-model="providerAcronym" />
        <c-help-block />
      </validation-provider>
    </div>
    <hr />

    <div class="mb-3">
      <p class="form-label">First Name</p>
      <div class="row">
        <!-- title -->
        <validation-provider tag="div" class="col-4 col-sm-3" name="title" rules="required">
          <label class="visually-hidden">Title</label>
          <single-select v-model="title" :options="optionsTitle" :preselect="true"/>
          <c-help-block />
        </validation-provider>
        <!-- first name -->
        <validation-provider tag="div" class="col" name="first name" rules="required">
          <label class="visually-hidden">First Name</label>
          <c-input type="text" v-model="firstName" />
          <c-help-block />
        </validation-provider>
      </div>
    </div>

    <!-- last name -->
    <validation-provider tag="div" class="mb-3" name="last name" rules="required">
      <label class="form-label">Last Name</label>
      <c-input type="text" v-model="lastName" />
      <c-help-block />
    </validation-provider>

    <!-- mobile -->
    <validation-provider tag="div" class="mb-3" name="mobile number" vid="mobile" :rules="{ required: true }" v-slot="{ failed }">
      <label class="form-label">Mobile number</label>
      <div class="input-group input-group-blend mb-3" :class="{ 'is-invalid': failed }">
        <imask-input
          v-model="mobile"
          mask="0000 000 000"
          :unmask="true"
          :class="{ 'is-invalid': failed }"
          class="form-control"
        />
      </div>
      <c-help-block />
    </validation-provider>

    <!-- email -->
    <validation-provider tag="div" class="mb-3" name="email" rules="required|email">
      <label class="form-label">Email</label>
      <c-input type="email" v-model="email"/>
      <c-help-block/>
    </validation-provider>

    <!-- password -->
    <validation-provider tag="div" name="password" rules="required|min:12" class="mb-3">
      <label class="form-label">Password</label>
      <c-input type="password" v-model="password"/>
      <c-help-block/>
    </validation-provider>
    <password-strength-bar v-bind="{ password, label: 'Password Strength' }" class="mb-3" />

    <div class="mb-4 mt-4">
      <!-- terms -->
      <validation-provider tag="div" name="terms and conditions" vid="agree_terms" :rules="{ required: { allowFalse: false } }">
        <c-checkbox v-model="termsAccepted">
          I agree to the
          <a href="https://medcpd.com/privacy-policy/" class="text-decoration-underline" target="_blank">privacy policy</a>
          and
          <router-link class="text-decoration-underline" :to="{ name: 'terms' }" target="_blank">terms and conditions</router-link>
            of use.
        </c-checkbox>
      </validation-provider>

      <!-- marketing -->
      <validation-provider tag="div" name="marketing" vid="agree_marketing" :rules="{ required: { allowFalse: true } }">
        <c-checkbox v-model="receiveMarketing">
          I would like to receive occasional marketing communication from Med CPD.
        </c-checkbox>
      </validation-provider>

    </div>

    <slot name="button" v-bind="{ payload }"/>
  </fragment>
</template>

<script>
import PasswordStrengthBar from '@/components/forms/PasswordStrengthBar'
import SingleSelect from '../../components/forms/SingleSelect.vue'
import { IMaskComponent } from 'vue-imask'

export default {
  components: {
    PasswordStrengthBar,
    SingleSelect,
    'imask-input': IMaskComponent,
  },

  data () {
    return {
      company: null,
      providerAcronym: null,
      title: 'Mr',
      firstName: null,
      lastName: null,
      mobile: null,
      email: null,
      password: null,
      termsAccepted: false,
      receiveMarketing: false,
      optionsTitle: [
        { label: 'Dr', value: 'Dr' },
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Prof', value: 'Prof' },
        { label: 'A/Prof', value: 'A/Prof' },
      ]
    }
  },

  computed: {
    payload () {
      return {
        company: this.company,
        provider_acronym: this.providerAcronym,
        title: this.title,
        first_name: this.firstName,
        last_name: this.lastName,
        mobile: this.mobile,
        email: this.email,
        password: this.password,
        terms_accepted: this.termsAccepted,
        receive_marketing: this.receiveMarketing,
        participate_feedback: this.participateFeedback
      }
    }
  }
}
</script>
