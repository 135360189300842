<template>
  <multiselect
    :options="options"
    :internal-search="false"
    placeholder="Select"
    :preselect-first="preselect"
    :show-labels="false"
    :label="label"
    :track-by="trackBy"
    v-model="selected"
    :class="[invalidClass]"
  />
</template>

<script>
import { useIsInvalid } from '@chameleon/chameleon-vue'
import { bus } from '../../main'
import { get, call } from 'vuex-pathify'

export default {
  mixins: [useIsInvalid],

  props: {
    value: undefined,
    countryId: null
  },

  data () {
    return {
      trackBy: 'id',
      label: 'name',
      preselect: false,
    }
  },

  async created () {
    await this.getUniversities({ params: { countryId: this.countryId } })

    bus.$on('updateUniversities', countryId => {
      this.getUniversities({ params: { countryId: countryId } })
    })
  },

  computed: {
    universities: get('lists/universities/universities'),

    options () {
      const options = [...this.universities]

      if (this.prependAll) {
        options.unshift({ name: 'All', id: null })
      }

      return options
    },
    selected: {
      get () {
        if (this.value) {
          return this.options.find(({ id }) => id === this.value) || this.preselectFirst
        }
        return this.preselectFirst
      },
      set (option) {
        this.$emit('input', option ? option.id : this.preselectFirst)
      }
    },

    preselectFirst () {
      return this.preselect ? this.options[0].id : null
    }
  },

  methods: {
    getUniversities: call('lists/universities/getUniversities')
  }
}
</script>
