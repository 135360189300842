<template>
  <fragment>
    <h1>Let us know more about your organisation</h1>
    <h2 class="h3 mb-4">{{ provider.name }}</h2>
    <p class="mb-4">These details will be visible on your organisations profile page for users to view.</p>

    <!-- address -->
    <validation-provider tag="div" class="mb-4" name="address" rules="required">
      <label class="form-label">Office Address</label>
      <c-input v-model="address" />
      <c-help-block />
    </validation-provider>

    <!-- website -->
    <validation-provider tag="div" class="mb-4" name="website" rules="required|url">
      <label class="form-label">Website</label>
      <c-input v-model="website" />
      <c-help-block />
    </validation-provider>

    <!-- email -->
    <validation-provider tag="div" class="mb-4" name="email" rules="required|email">
      <label class="form-label">Contact Email</label>
      <c-input type="email" v-model="email" />
      <c-help-block />
    </validation-provider>

    <!-- phone number -->
    <validation-provider tag="div" class="mb-4" name="phone number" rules="required">
      <label class="form-label">Phone Number</label>
      <c-input v-model="phone_number" />
      <c-help-block />
    </validation-provider>

    <slot name="button" v-bind="{ payload }"/>
  </fragment>
</template>

<script>
import { helperComputed } from '@/vuex/modules/providers'

export default {
  data () {
    return {
      address: null,
      website: null,
      email: null,
      phone_number: null,
    }
  },

  computed: {
    ...helperComputed,

    payload () {
      return {
        address: this.address,
        website: this.website,
        email: this.email,
        phone_number: this.phone_number,
      }
    }
  },

  methods: {
    removeQualification (item) {
      const index = this.qualifications.findIndex((qualification) => qualification.id === item.id)
      this.qualifications.splice(index, 1)
    }
  },
}
</script>
