<template>
  <div class="card card-authentication">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>
      <div class="row">
        <div class="col-auto">
          <div class="pb-2">
            <div class="swal-icon swal-icon--error mx-2">
              <div class="swal-icon--error__x-mark">
                <span
                  class="swal-icon--error__line swal-icon--error__line--left"
                ></span>
                <span
                  class="swal-icon--error__line swal-icon--error__line--right"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <h1 class="mb-4">Account Verification Failed.</h1>

          <p class="mb-4">
            <template v-if="error">{{ error }}.</template>
            <template v-else>
              We were unable to verify your email. This is likely due to the
              verification link expiring.
            </template>
          </p>

          <div class="text-right">
            <verification-resend />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import VerificationResend from './AccountVerificationResend'

export default {
  props: ['error'],

  components: {
    VerificationResend
  }
}
</script>
