<template>
  <div class="row mb-3">
    <validation-provider tag="div" class="col-md-4 col-xs-12" name="day" rules="required" v-slot="{ failed }">
      <multiselect
        placeholder="Day"
        :options="dayOptions"
        v-model="daySelected"
        :preselect-first="false"
        :show-labels="false"
        :allow-empty="false"
        :class="{ 'is-invalid': failed }"
      />
      <c-help-block/>
    </validation-provider>

    <validation-provider tag="div" class="col-md-4 col-xs-12" name="month" rules="required" v-slot="{ failed }">
      <multiselect
        placeholder="Month"
        :options="monthOptions"
        v-model="monthSelected"
        :preselect-first="false"
        :show-labels="false"
        :allow-empty="false"
        :class="{ 'is-invalid': failed }"
      />
    <c-help-block/>
    </validation-provider>

    <validation-provider tag="div" class="col-md-4 col-xs-12" name="year" rules="required" v-slot="{ failed }">
      <multiselect
        placeholder="Year"
        :options="yearOptions"
        v-model="yearSelected"
        :preselect-first="false"
        :show-labels="false"
        :allow-empty="false"
        :class="{ 'is-invalid': failed }"
      />
    <c-help-block/>
    </validation-provider>
  </div>
</template>

<script>
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: undefined,
  },

  data () {
    return {
      day: null,
      month: null,
      year: null,
      dayOptions: [],
      monthOptions: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      yearOptions: []
    }
  },

  async created () {
    for (let i = 1; i < 32; i++) {
      this.dayOptions.push(i)
    }

    const year = (new Date()).getFullYear()

    for (let i = year; i > 1899; i--) {
      this.yearOptions.push(i)
    }
  },

  methods: {
    formatDate (inputDate) {
      let date = inputDate.getDate()
      let month = inputDate.getMonth() + 1
      const year = inputDate.getFullYear()

      date = date.toString().padStart(2, '0')
      month = month.toString().padStart(2, '0')

      return `${year}-${month}-${date}`
    },

    setDob () {
      const month = this.monthOptions.indexOf(this.month)
      const value = this.formatDate(new Date(this.year, month, this.day))
      this.$emit('input', value)
    }
  },

  computed: {
    daySelected: {
      get () {
        return this.day
      },

      set (value) {
        this.day = value
        this.setDob()
      }
    },

    monthSelected: {
      get () {
        return this.month
      },

      set (value) {
        this.month = value
        this.setDob()
      }
    },

    yearSelected: {
      get () {
        return this.year
      },

      set (value) {
        this.year = value
        this.setDob()
      }
    }
  }
}
</script>
